import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { useApp } from "../../contexts/AppContext";
import { db } from "../../utils/firebase";

import Button from "../Button/Button";

const partiesRef = collection(db, "parties");

function JoinParty() {
  const {
    state: { user, event },
  } = useApp();
  const [canJoin, setCanJoin] = useState(false);
  const [partyId, setPartyId] = useState("");
  const [isJoining, setIsJoining] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function joinParty(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!partyId || partyId.length < 6 || !user || !event || isJoining) return;

    setIsJoining(true);
    setErrorMessage("");

    const q = query(partiesRef, where("shortId", "==", parseInt(partyId, 10)));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      setErrorMessage(`Unable to find a party with ID "${partyId}"`);
      setIsJoining(false);
      return;
    }

    if (querySnapshot.docs.length > 1) {
      setErrorMessage(
        `Sorry, but we have a small hickup, please join another party`
      );
      setIsJoining(false);
      return;
    }

    const _party = querySnapshot.docs[0];

    // Create user object and add to party
    const partyUser = {
      active: true,
      userId: user.uid,
      name: user.displayName,
      partyId: _party.id,
      eventId: event._docId,
      created: serverTimestamp(),
    };

    await addDoc(collection(db, "partyUsers"), partyUser);

    setIsJoining(false);
  }

  return (
    <>
      <p>Enter the 7-digit ID and join them!</p>
      <form
        className="mt-6 flex flex-col items-center"
        autoComplete="off"
        onSubmit={joinParty}
      >
        <div className="mb-4">
          <input
            id="partyid"
            className="text-center appearance-none text-2xl tracking-widest border w-full py-2 px-3 text-esc-secondary leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="id"
            value={partyId}
            onChange={(event) => {
              setErrorMessage("");
              setPartyId(event.target.value);
              setCanJoin(event.target.value.length === 7);
            }}
            placeholder="Eg. '1234567'"
            maxLength={7}
          />
        </div>
        <Button
          type="submit"
          variant="primary"
          disabled={!canJoin || isJoining}
          spinner={isJoining}
          size="xl"
          className="shadow-xl"
        >
          {isJoining ? "Joining" : "Join party"}
        </Button>

        <div className="mt-4 text-esc-neutral-600 text-lg text-center">
          {errorMessage && <span>{errorMessage}</span>}
          {!errorMessage && <span>&nbsp;</span>}
        </div>
      </form>
    </>
  );
}

export default JoinParty;
