import React from "react";

import countries from "../../utils/countries";

function SongData({
  country,
  artist,
  title,
  position,
}: {
  country: Country;
  artist: string;
  title: string;
  position: number;
}) {
  return (
    <>
      <div className="flex justify-between items-center leading-none mb-2">
        <div className="inline-flex items-center">
          <span className={`fi fi-${country} w-6 h-5 mr-2`}></span>
          <span className="font-montserrat uppercase">
            {countries[country as keyof typeof countries] ?? country}
            {country === "il" && <span className="ml-1">💩</span>}
          </span>
        </div>
        <div className="absolute right-0 top-0 bg-esc-accent py-1.5 px-2.5 leading-none text-esc-primary font-bold text-2xl tracking-widest">
          {position.toString().padStart(2, "0")}
        </div>
      </div>
      <div className="font-italic text-2xl font-montserrat leading-none mb-2">
        {title}
      </div>
      <div className="text-sm italic leading-none">{artist}</div>
    </>
  );
}

export default SongData;
