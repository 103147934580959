import React from "react";
import clsx from "clsx";

import SongData from "../SongData/SongData";

function OrderSongItem({
  score,
  position,
  isDragging,
  song,
  className,
}: {
  score: number;
  position: number;
  isDragging: boolean;
  song?: Song;
  className?: string;
}) {
  if (!song) return null;

  const cssClass = clsx(
    "text-left justify-between items-start bg-esc-neutral-800 mb-4 p-4 pb-2 relative",
    isDragging && "bg-esc-secondary drop-shadow-2xl ",
    className
  );

  return (
    <div className={cssClass}>
      <SongData
        title={song.title}
        artist={song.artist}
        country={song.country}
        position={position}
      />
      <div className="flex justify-center items-start mt-3 border-t border-esc-neutral-300 pt-1">
        <span className="text-sm">
          You gave this entry{" "}
          <span
            className={`text-xl ${
              isDragging ? "text-esc-primary" : "text-esc-secondary"
            }`}
          >
            {score}
          </span>{" "}
          points
        </span>
      </div>
    </div>
  );
}

export default OrderSongItem;
