const categories: Category[] = [
  {
    key: "song",
    label: "Song",
    icon: "GiMusicalNotes",
    score: true,
  },
  {
    key: "performance",
    label: "Show & performance",
    icon: "GiContortionist",
    score: true,
  },
  {
    key: "outfit",
    label: "Outfit",
    icon: "GiClothes",
    score: true,
  },
  {
    key: "escfactor",
    label: "ESC Factor",
    icon: "GiPartyPopper",
    score: true,
  },
];

export default categories;
