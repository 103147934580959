import React, { useState } from "react";
import clsx from "clsx";
import { Range } from "react-range";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";

import categories from "../../utils/categories";
import { db } from "../../utils/firebase";
import { useApp } from "../../contexts/AppContext";
import { pointToValue, valueToPoint } from "../../utils/utils";

import Button from "../Button/Button";
// import Icon from "../Icon/Icon";

const insetTable = {
  0: 100,
  1: 87,
  2: 77.5,
  3: 68,
  4: 58.5,
  5: 49,
  6: 39.5,
  7: 30,
  8: 20.5,
  10: 10.5,
  12: 0,
};

function RateSong({
  _docId,
  onClose,
}: {
  _docId: string;
  onClose: () => void;
}) {
  const {
    state: { partyUser, userScore },
  } = useApp();

  // Get initial stored scores for this song
  const intialScore = userScore ? userScore.data[_docId] : undefined;
  // const intialScore = {
  //   song: 0,
  //   performance: 0,
  //   show: 0,
  //   outfit: 0,
  //   escfactor: 0,
  // };

  const [state, setState] = useState({
    song: intialScore?.song ?? 0,
    performance: intialScore?.performance ?? 0,
    show: intialScore?.show ?? 0,
    outfit: intialScore?.outfit ?? 0,
    escfactor: intialScore?.escfactor ?? 0,
  });

  const doSave = async (nextScores: any) => {
    await setDoc(doc(db, "scores", partyUser?._docId || "foo"), nextScores, {
      merge: true,
    });
  };

  const handleSave = async () => {
    const nextScores = {
      ...userScore,
      ...(userScore
        ? { updated: serverTimestamp() }
        : { created: serverTimestamp() }),
      partyId: partyUser?.partyId || "foobar",
      data: {
        ...userScore?.data,
        [_docId]: {
          ...state,
        },
      },
    };
    await doSave(nextScores);
    // onClose();
  };

  const handleChange = async (key: string, value: number) => {
    const nextState = {
      ...state,
      [key]: value,
    };
    await setState(nextState);
  };

  const totalScore = Object.values(state).reduce((a, b) => a + b, 0);

  return (
    <div className="text-white mt-2">
      <div className="border-t border-solid border-esc-neutral-300 pt-4">
        {categories
          .filter((c) => c.score)
          .map((category) => {
            const { key, label } = category;
            const score = state[key as keyof typeof state];
            return (
              <div key={key} className="flex flex-col mb-4 last:mb-0">
                <span className="flex justify-between mb-1">
                  <span className="inline-flex text-lg text-white">
                    <span>{label}</span>
                    {/* <span className="ml-2 text-xl text-white">
                      <Icon id={icon} />
                    </span> */}
                  </span>
                  <span className="text-white font-bold text-lg">{score}</span>
                </span>
                <div className="mb-2 mt-2">
                  <Range
                    values={[pointToValue(score)]}
                    step={1}
                    min={0}
                    max={10}
                    onChange={(values) =>
                      handleChange(key, valueToPoint(values[0]))
                    }
                    onFinalChange={(values) => handleSave()}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        className="w-full h-4 bg-esc-neutral-700 rounded-full relative"
                      >
                        <div ref={props.ref} className="h-6 w-100 mx-3">
                          <div
                            className="rounded-full w-full absolute left-0 -top-1 bg-black h-6 bg-gradient-to-r from-esc-orange to-esc-secondary-700 "
                            style={{
                              clipPath: `inset(0% ${
                                insetTable[score as keyof typeof insetTable]
                              }% 0% 0% round 999px)`,
                            }}
                          />
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        className={clsx(
                          "rounded-full w-6 h-6 -top-1",
                          !isDragged &&
                            "bg-esc-accent-400 ring-1 ring-esc-accent-200 shadow-md shadow-esc-accent-700",
                          isDragged &&
                            "bg-esc-accent-200 ring-1 ring-esc-accent-100 shadow-lg shadow-esc-accent-800"
                        )}
                        style={{
                          ...props.style,
                        }}
                      >
                        <div className="relative -top-1 -left-1 w-8 h-8" />
                      </div>
                    )}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="flex items-center mt-4 pt-2 justify-between">
        <span className="text-2xl">Total: {totalScore}</span>
        <Button onClick={onClose}>Done</Button>
      </div>
    </div>
  );
}

export default RateSong;
