import React, { createContext, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { doc, query, where, collection } from "firebase/firestore";

import { auth, db } from "../utils/firebase";

const eventsRef = collection(db, "events");
const partyUsersRef = collection(db, "partyUsers");
// const placementsRef = collection(db, "placements");
const scoresRef = collection(db, "scores");
// const usersRef = collection(db, "users");

const initialState: AppState = {
  isReady: false,
};

const AppContext = createContext<AppContext>({ state: initialState });

function AppProvider({ children }: { children: React.ReactElement }) {
  const [user, userLoading] = useAuthState(auth, {
    // onUserChanged: async (user) => {
    //   console.log("user changed?");
    // },
  });

  // console.log({ user });

  const [globalUser] = useDocumentData(doc(db, "users", user?.uid ?? "foobar"));

  // Listen for an active event
  const [eventValue, eventLoading] = useCollection(
    query(eventsRef, where("active", "==", true))
  );

  const event =
    !eventLoading && eventValue?.docs?.length === 1
      ? ({
          ...eventValue.docs[0].data(),
          _docId: eventValue.docs[0].id,
        } as ESCEvent)
      : undefined;

  // console.log({ eventValue }, { eventLoading }, { event });

  // For overriding while developing
  // const eventLoading = false;
  // const event = {
  //   active: true,
  //   // stage: "pre-contest",
  //   // stage: "score-songs",
  //   // stage: "calculate-placement",
  //   // stage: "guess-placement",
  //   // stage: "calculate-results",
  //   // stage: "show-results",
  //   // stage: "after-contest",

  //   title: "Grand-local-2",
  //   // _docId: "KCDZFg5yaVprgFH9tyro", // Semi final 1
  //   // _docId: "T5HrGZ16NOBxfeQ3JYib", // Semi final 2
  //   _docId: "qsuTJ9ynxpqwQdKiGrpe", // Grand Final
  // };

  // Get an active local user for the current event / party
  const [partyUserValue, partyUserLoading] = useCollection(
    query(
      partyUsersRef,
      ...[
        where("active", "==", true),
        where("userId", "==", user?.uid ?? "foobar"),
        where("eventId", "==", event?._docId ?? "foobar"),
      ]
    )
  );

  const partyUser =
    !partyUserLoading && partyUserValue?.docs?.length === 1
      ? ({
          ...partyUserValue.docs[0].data(),
          _docId: partyUserValue.docs[0].id,
        } as PartyUser)
      : undefined;

  // Get all scores for user
  const [userScore] = useDocumentData(
    doc(db, "scores", partyUser?._docId ?? "foobar")
  );

  // Get all scores stores for the local user
  const [party] = useDocumentData(
    doc(db, "parties", partyUser?.partyId ?? "foobar")
  );

  // Get all scores from users in your party
  const [partyScoresValue, partyScoresLoading] = useCollection(
    query(scoresRef, where("partyId", "==", partyUser?.partyId ?? "foobar"))
  );

  // Define object holding all party scores per song and per user
  const partyScores: PartyScores | undefined = !partyScoresLoading
    ? {}
    : undefined;

  // Iterate all scores from users in your party, then group them by songId
  if (
    !partyScoresLoading &&
    partyScores &&
    partyScoresValue &&
    partyScoresValue?.docs?.length >= 0
  ) {
    partyScoresValue.docs.forEach((doc) => {
      const userId = doc.id;
      const docData = doc.data();
      Object.keys(docData.data).forEach((songId) => {
        // @ts-ignore
        if (!partyScores[songId]) {
          // @ts-ignore
          partyScores[songId] = {};
        }

        // @ts-ignore
        partyScores[songId][userId] = docData.data[songId];
      });
    });
  }

  // Get all users in your party
  const [partyUsersValue, partyUsersLoading] = useCollection(
    query(partyUsersRef, where("partyId", "==", partyUser?.partyId ?? "foobar"))
  );

  const partyUsers = !partyUsersLoading ? {} : undefined;

  if (
    !partyUsersLoading &&
    partyUsers &&
    partyUsersValue &&
    partyUsersValue?.docs?.length >= 0
  ) {
    partyUsersValue.docs.forEach((doc) => {
      const docData = doc.data();
      // @ts-ignore
      partyUsers[doc.id] = { ...docData, _docId: doc.id };
    });
  }

  // Get all placements guessed by the user in this party
  const [placements] = useDocumentData(
    doc(db, "placements", partyUser?._docId ?? "foobar")
  );

  return (
    <AppContext.Provider
      value={{
        state: {
          isReady: !userLoading,
          user,
          event,
          partyUser,
          userScore: userScore as UserScore,
          party: party as Party,
          partyScores,
          partyUsers: partyUsers as PartyUser[],
          globalUser: globalUser as AppUser,
          placements: placements as UserPlacement,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

function useApp() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within the AppProvider");
  }

  return context;
}

export { AppProvider, useApp };
