export const countries = {
  al: "Albania",
  am: "Armenia",
  at: "Austria",
  au: "Australia",
  az: "Azerbaijan",
  be: "Belgium",
  bg: "Bulgaria",
  ch: "Switzerland",
  cy: "Cyprus",
  cz: "Czech Republic",
  de: "Germany",
  dk: "Denmark",
  ee: "Estonia",
  es: "Spain",
  fi: "Finland",
  fr: "France",
  gb: "United Kingdom",
  ge: "Georgia",
  gr: "Greece",
  hr: "Croatia",
  ie: "Ireland",
  is: "Iceland",
  il: "Israel",
  it: "Italy",
  lt: "Lithuania",
  lv: "Latvia",
  md: "Moldova",
  me: "Montenegro",
  mk: "North Macedonia",
  mt: "Malta",
  nl: "Netherlands",
  no: "Norway",
  pl: "Poland",
  pt: "Portugal",
  ro: "Romania",
  rs: "Serbia",
  se: "Sweden",
  si: "Slovenia",
  sm: "San Marino",
  ua: "Ukraina",
};

export default countries;
