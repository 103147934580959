import React from "react";
import clsx from "clsx";

import { getTotalScore } from "../../utils/utils";

import SongData from "../SongData/SongData";
import RateSong from "../RateSong/RateSong";
import UserRatings from "../UserRatings/UserRatings";

function SongItem({
  onOpen,
  onClose,
  open,
  disabled,
  ...props
}: {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  disabled: boolean;
  artist: string;
  title: string;
  country: Country;
  score?: Score;
  order: number;
  _docId: string;
  userScores: Scores;
  partyUsers: any[];
}) {
  const { title, artist, country, score, order, userScores, partyUsers } =
    props;

  const ref = React.useRef<HTMLDivElement>(null);

  const totalScore = score ? getTotalScore(score) : "?";

  // Compute the combined score for song from each user in the party
  const partyScore =
    userScores && Object.keys(userScores).length > 0
      ? Object.keys(userScores).reduce((curr, _userId) => {
          const s = userScores[_userId];
          return curr + getTotalScore(s);
        }, 0) / Object.keys(userScores).length
      : "?";

  React.useEffect(() => {
    if (open && ref.current) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [open]);

  return React.createElement(
    open ? "div" : "button",
    {
      className: clsx(
        "text-left justify-between items-start bg-esc-neutral-800 p-4 relative scroll-m-[6rem]",
        disabled && "opacity-10"
      ),
      onClick: !open ? onOpen : null,
      disabled,
      ref,
    },
    <>
      <SongData
        title={title}
        artist={artist}
        country={country}
        position={order}
      />
      {!open && (
        <div className="flex justify-between mt-3 border-t border-esc-neutral-300 pt-1">
          <div>
            <span className="text-base">Your points: </span>
            <span className="text-xl text-esc-secondary">{totalScore}</span>
          </div>
          <div>
            <span className="text-base">Avg.: </span>
            <span className="text-xl text-esc-secondary">
              {typeof partyScore === "number"
                ? partyScore.toFixed(1)
                : partyScore}
            </span>
            {userScores && Object.keys(userScores).length > 0 && (
              <span className="text-sm inline-flex items-center ml-1">
                ({Object.keys(userScores).length})
              </span>
            )}
          </div>
        </div>
      )}
      {open && <RateSong {...props} onClose={onClose} />}
      {open && <UserRatings userScores={userScores} partyUsers={partyUsers} />}
    </>
  );
}

export default SongItem;
