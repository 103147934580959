const valueToPointTable: Point = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 10,
  10: 12,
};

interface PointTable {
  [key: number]: number;
}

export const getTotalScore = (scoreObj: Score) =>
  Object.keys(scoreObj).reduce(
    (curr, key) => curr + scoreObj[key as keyof Score],
    0
  );

// Return the associated point for a given value
export const valueToPoint = (step: number) =>
  valueToPointTable[step as keyof Point];

// Return the associated value for a given point
export const pointToValue = (point: number) => {
  const flipped: PointTable = Object.entries(valueToPointTable).reduce(
    (obj, [key, value]) => ({ ...obj, [value]: key }),
    {}
  );

  return flipped[point];
};
