import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import { useApp } from "../../contexts/AppContext";
import { db } from "../../utils/firebase";

import Button from "../Button/Button";

function CreatParty({ onCancel }: { onCancel: () => void }) {
  const {
    state: { user, event },
  } = useApp();
  const [canCreate, setCanCreate] = useState(false);
  const [name, setName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function createParty(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!canCreate || !event || !user || isCreating) return;

    setIsCreating(true);
    setErrorMessage("");

    try {
      // Generate a random 7 digit number
      // Check if it exists in the database
      // If it does, generate a new one
      // If it doesn't, create the party

      let iterations = 0;
      const maxIterations = 5;
      let id = null;

      while (id === null && iterations < maxIterations) {
        id = Math.floor(Math.random() * (9999999 - 1000000 + 1) + 1000000);

        const q = query(collection(db, "parties"), where("shortId", "==", id));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0) {
          id = null;
          iterations++;
        }
      }

      if (id === null) {
        setIsCreating(false);
        setErrorMessage("Unable to create party, please try again");
        return;
      }

      const docRef = await addDoc(collection(db, "parties"), {
        shortId: id,
        eventId: event._docId,
        name,
        created: serverTimestamp(),
        createdBy: user.uid,
      });

      if (docRef.id) {
        const partyUser = {
          active: true,
          userId: user.uid,
          name: user.displayName,
          partyId: docRef.id,
          eventId: event._docId,
          created: serverTimestamp(),
        };

        await addDoc(collection(db, "partyUsers"), partyUser);
        // setIsCreating(false);
      }
    } catch (err) {
      console.warn("Error creating party", err);
      setIsCreating(false);
    }
  }

  return (
    <form autoComplete="off" onSubmit={createParty}>
      <div className="mb-4">
        <label
          className="block text-lg text-esc-neutral font-medium mb-2"
          htmlFor="partyname"
        >
          Name of party
        </label>
        <input
          id="partyname"
          className="text-xl text-esc-secondary  appearance-none border w-full py-2 px-3 text-esc-neutral leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          name="name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
            setCanCreate(event.target.value.length >= 2);
          }}
          placeholder="Eg. 'Homers ESC Bonanza!'"
          maxLength={23}
        />
      </div>
      {errorMessage && (
        <div className="mb-4 text-esc-accent-200 px-4 text-center leading-tight">
          {errorMessage}
        </div>
      )}

      <div className="flex justify-between">
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!canCreate || isCreating}
          spinner={isCreating}
        >
          {isCreating ? "Creating" : "Create"}
        </Button>
      </div>
    </form>
  );
}

export default CreatParty;
