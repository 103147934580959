import React from "react";
import clsx from "clsx";

import { useApp } from "../../contexts/AppContext";
import { GradientText } from "../GradientText/GradientText";

function ShowResults() {
  const {
    state: { partyUsers, partyUser },
  } = useApp();

  if (!partyUsers) return null;

  let previousUserScore = -1;
  let previousPlacement = -1;

  const sorted = Object.values(partyUsers)
    .map((obj) => ({
      ...obj,
      finalScore: obj.finalScore || 0,
    }))
    .sort((a, b) => b.finalScore - a.finalScore)
    .map((obj: any, index: number) => {
      const showIndex = obj.finalScore !== previousUserScore;
      const finalPlacement = showIndex ? index + 1 : previousPlacement;

      previousUserScore = obj.finalScore;
      previousPlacement = finalPlacement;

      return {
        ...obj,
        finalPlacement,
        showPlacement: showIndex,
      };
    });

  // Get the placement of the current user
  const userPlacement = sorted.reduce((acc, obj) => {
    if (obj.userId === partyUser?.userId) {
      return obj.finalPlacement;
    }
    return acc;
  }, -1);

  return (
    <div className="flex-1">
      <GradientText className="my-8">Final results!</GradientText>

      {[1, 2, 3].includes(userPlacement) && (
        <div className="text-esc-primary text-center text-3xl mb-8">
          {userPlacement === 1 && `You won! 🥇`}
          {userPlacement === 2 && `You came in second! 🥈`}
          {userPlacement === 3 && `You came in third! 🥉`}
        </div>
      )}

      <div className="w-full bg-esc-neutral-600 divide-esc-neutral-800 divide-y-2 mb-5">
        {sorted.map((_userData, index) => {
          return (
            <div
              key={_userData.userId}
              className={clsx(
                `flex justify-start odd:bg-esc-neutral-700 py-2 px-3 text-2xl `
              )}
            >
              <span className="w-6 mr-2">
                {_userData.showPlacement && `${_userData.finalPlacement}.`}
              </span>
              <span className="flex-1">
                {_userData.name}{" "}
                <span>{partyUser?.userId === _userData.userId && "👑"}</span>
              </span>
              <span className="text-esc-secondary">{_userData.finalScore}</span>
            </div>
          );
        })}
      </div>

      <p className="text-center text-xl px-4">Thanks for participating!</p>
    </div>
  );
}

export default ShowResults;
