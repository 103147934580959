import React from "react";

import { getTotalScore } from "../../utils/utils";

function UserRatings({
  partyUsers,
  userScores,
}: {
  partyUsers: any;
  userScores: Scores;
}) {
  if (!userScores) return null;

  return (
    <div className="mt-6">
      {Object.keys(partyUsers).map((_userId) => {
        const _userData = partyUsers[_userId];
        if (!userScores[_userId]) return null;

        const scores = userScores[_userId];
        const score = getTotalScore(scores);

        return (
          <div
            key={_userId}
            className="flex items-center justify-between even:bg-esc-neutral-700 odd:bg-esc-neutral-600 p-1.5 px-3"
          >
            <span>{_userData.name}</span>
            <span className="text-esc-secondary tracking-widest text-lg">
              {score}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default UserRatings;
