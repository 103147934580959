import React from "react";

export type GradientBackgroundProps = {
  steps?: 3 | 4 | 6 | 8 | 12 | 16;
  direction?: "vertical" | "horizontal";
  animate?: boolean;
  gradient?: "pink-yellow" | "turquoise-yellow";
  wrapperClass?: string;
};

function GradientBackground({
  steps = 8,
  direction = "vertical",
  animate = false,
  gradient = "pink-yellow",
  children,
  wrapperClass = "",
}: GradientBackgroundProps & { children: React.ReactNode }) {
  // Calculate the number of bars to add into the gradient
  const barSizesMap = {
    3: [15, 70, 15],
    4: [15, 35, 35, 15],
    6: [10, 16, 25, 25, 15, 10],
    8: [5, 10, 15, 20, 20, 15, 10, 5],
    12: [2, 3, 5, 10, 13, 17, 17, 13, 10, 5, 3, 2],
    16: [1, 2, 3, 5, 7, 10, 12, 15, 15, 12, 10, 7, 5, 3, 2, 1],
  };

  const barSizes = barSizesMap[steps];

  const cssGradient =
    gradient === "pink-yellow"
      ? "bg-gradient-pink-yellow"
      : "bg-gradient-turquoise-yellow";

  const cssAnimated = animate ? "bg-gradient-bar-animated" : "";

  const cssDirection =
    direction === "vertical"
      ? "top-0 bottom-0 bg-gradient-vertical"
      : "left-0 right-0 bg-gradient-horizontal";

  const cssClass = `absolute bg-gradient bg-gradient-bar ${cssGradient} ${cssAnimated} ${cssDirection}`;

  // Create array of divs for the number of bars to add
  const bars = barSizes.map((barSize, index) => {
    // Summarize the size of the previous bars to get the position
    const position = barSizes
      .slice(0, index)
      .reduce((acc, cur) => acc + cur, 0);
    return (
      <div
        key={index}
        className={cssClass}
        style={
          {
            [`--index`]: index,
            [`--position`]: position,
            [`--size`]: barSize,
          } as React.CSSProperties
        }
      ></div>
    );
  });

  return (
    <>
      <div className="absolute left-0 top-0 right-0 bottom-0">{bars}</div>
      <div className={`${wrapperClass} relative z-10`}>{children}</div>
    </>
  );
}

export default GradientBackground;
