import React, { useState } from "react";

import { useApp } from "../../contexts/AppContext";

import SongItem from "../SongItem/SongItem";

function ScoreSongs({ songs }: { songs: Song[] }) {
  const [openSong, setOpenSong] = useState<Country | null>(null);
  const {
    state: { userScore, partyScores, partyUsers },
    // state: { userScore, partyScores },
  } = useApp();

  // console.log(songs);

  return (
    <div className="flex-1 pb-6">
      <p className="text-lg my-4 text-center">Give your points to each song!</p>
      {songs && (
        <div className="flex flex-col items-stretch space-y-6">
          {songs.map((song) => (
            <SongItem
              key={song.country}
              score={
                userScore
                  ? userScore.data[song._docId as keyof Scores]
                  : undefined
              }
              // score={0}
              userScores={
                partyScores && partyScores[song._docId]
                  ? partyScores[song._docId]
                  : {}
              }
              partyUsers={partyUsers || []}
              {...song}
              onOpen={() => setOpenSong(song.country)}
              onClose={() => setOpenSong(null)}
              open={song.country === openSong}
              disabled={openSong && song.country !== openSong ? true : false}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ScoreSongs;
