import React, { useState } from "react";

import { useApp } from "../../contexts/AppContext";

import Button from "../Button/Button";
import GradientDivider from "../GradientDivider/GradientDivider";
import Modal from "../Modal/Modal";
import CreateParty from "./CreateParty";
import JoinParty from "./JoinParty";
import { GradientText } from "../GradientText/GradientText";
import GradientBackground from "../GradientBackground/GradientBackground";

function CreateOrJoinParty() {
  const {
    state: { event, globalUser },
  } = useApp();

  const [showCreate, setShowCreate] = useState(false);

  if (!event || !globalUser) return null;

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/* <div className="bg-esc-neutral-800 py-2 px-4 text-center text-esc-secondary">
          Current event: {event.title}
        </div> */}
        <div className="flex-1 flex flex-col justify-center items-center relative">
          <GradientBackground
            gradient="turquoise-yellow"
            direction="horizontal"
            steps={4}
          >
            <div className="text-center">
              <p className="mb-4 px-2 text-4xl text-center">
                Hi {globalUser.nickName}!
              </p>
              <p className="mb-8 text-2xl text-center">
                Create a party and invite
                <br />
                your friends and family
                <br />
                to join!
              </p>
              <Button
                onClick={() => setShowCreate(true)}
                variant="primary"
                size="xl"
                className="shadow-xl"
              >
                Create party
              </Button>
            </div>
          </GradientBackground>
        </div>

        <div className="flex-1 flex flex-col justify-center items-center relative">
          <GradientBackground
            gradient="pink-yellow"
            direction="horizontal"
            steps={6}
          >
            <div className="text-center">
              <p className="mb-4 px-2 text-4xl text-center">
                Invited to
                <br />
                someones party?
              </p>
              <JoinParty />
            </div>
          </GradientBackground>
        </div>
      </div>
      {showCreate && (
        <Modal title="Create party" onClose={() => setShowCreate(false)}>
          <CreateParty onCancel={() => setShowCreate(false)} />
        </Modal>
      )}
    </>
  );
}

export default CreateOrJoinParty;
