import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";

import { db } from "../../utils/firebase";

import { useApp } from "../../contexts/AppContext";
import OrderSongItem from "../OrderSongItem/OrderSongItem";

function GuessPlacement({ songs }: { songs: Song[] }) {
  const {
    state: { partyUser, placements },
  } = useApp();

  const [localPlacements, setLocalPlacements] = useState<
    PlacementItem[] | null
  >(null);

  const doSave = async (nextPlacements: PlacementItem[]) => {
    if (!partyUser) return;

    await setLocalPlacements(nextPlacements);

    await setDoc(
      doc(db, "placements", partyUser._docId),
      {
        data: nextPlacements,
        updated: serverTimestamp(),
      },
      {
        merge: true,
      }
    );

    await setLocalPlacements(null);
  };

  async function handleDragEnd(result: DropResult) {
    if (!result.destination || !placements) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const source = result.source.index + 1;
    const destination = result.destination.index + 1;
    const moveUp = destination < source;

    const nextPlacementsData: PlacementItem[] = placements.data.map((_p) => {
      if (_p.songId === result.draggableId) {
        return {
          ..._p,
          placement: destination,
        };
      }

      return {
        ..._p,
        placement: moveUp
          ? _p.placement < source && _p.placement >= destination
            ? _p.placement + 1
            : _p.placement
          : _p.placement <= destination && _p.placement > source
          ? _p.placement - 1
          : _p.placement,
      };
    });

    await doSave(nextPlacementsData);
  }

  if (!songs || !placements) return null;

  const sorted = (localPlacements ?? placements.data).sort(
    (a, b) => a.placement - b.placement
  );

  // const placements = false;

  return (
    <div className="flex-1">
      <p className="text-lg my-4 text-center">
        Drag and drop to guess the final results!
      </p>
      {placements && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sorted.map((obj, index) => {
                  const song = songs.find((s) => s._docId === obj.songId);
                  return (
                    <Draggable
                      draggableId={obj.songId}
                      index={index}
                      key={obj.songId}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="mb-4 w-full"
                        >
                          <OrderSongItem
                            score={obj.score}
                            song={song}
                            position={obj.placement}
                            isDragging={snapshot.isDragging}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}

export default GuessPlacement;
