import React, { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where, collection, getDoc } from "firebase/firestore";

import PreContest from "../PreContest/PreContest";
import ScoreSongs from "../ScoreSongs/ScoreSongs";
import GuessPlacement from "../GuessPlacement/GuessPlacement";
import FullscreenMessage from "../FullscreenMessage/FullscreenMessage";
import ShowResults from "../ShowResults/ShowResults";
// import AfterContest from "../AfterContest/AfterContest";
import { useApp } from "../../contexts/AppContext";
import { db } from "../../utils/firebase";
import { GradientText } from "../GradientText/GradientText";
import { GradientBackgroundProps } from "../GradientBackground/GradientBackground";

const songsRef = collection(db, "eventSongs");

function LetsParty() {
  const {
    state: { event },
  } = useApp();

  const [songs, setSongs] = useState<Song[] | undefined>(undefined);

  const eventId = event && event._docId ? event._docId : "foobar";

  // Get songs from active event
  const [songsValue, songsLoading] = useCollection(
    query(songsRef, where("eventId", "==", eventId))
    // {
    //   snapshotListenOptions: { includeMetadataChanges: true },
    // }
  );

  useEffect(() => {
    const fetchSongs = async () => {
      // Get all song objects in the correct order
      const orderedSongs = songsValue
        ? await Promise.all(
            songsValue.docs.map(async (doc) => {
              const eventSong = { ...doc.data(), _docId: doc.id } as EventSong;
              const songRef = eventSong.song;
              const songDoc = await getDoc(songRef);
              const songData = songDoc.data() as Song;
              return {
                ...songData,
                order: eventSong.order,
                _docId: songDoc.id,
              } as Song;
            })
          ).then((temp) => temp.sort((a, b) => a.order - b.order))
        : undefined;

      setSongs(orderedSongs);
    };

    if (!songsLoading && songsValue && songsValue?.docs?.length >= 0) {
      fetchSongs();
    }
  }, [eventId, songsLoading, songsValue]);

  if (!event) return null;

  return (
    <>
      {event.stage === "pre-contest" && <PreContest />}
      {event.stage === "score-songs" && <ScoreSongs songs={songs ?? []} />}
      {event.stage === "calculate-placement" && (
        <FullscreenMessage
          bg={
            {
              animate: true,
              gradient: "turquoise-yellow",
            } as GradientBackgroundProps
          }
        >
          <p className="text-center mb-5 text-3xl">Please wait!</p>

          <p className="text-center mb-5">
            We are generating an ordered list based on your points!
          </p>

          <p className="text-center mb-8">
            Then it's up to you to drag and drop to guess what the final results
            will be. You will be awarded points based on how accurate you are.
          </p>

          <div className="w-full bg-esc-neutral-600 divide-esc-neutral-800 divide-y-2 mt-3 mb-5">
            <div className="flex justify-between items-center odd:bg-esc-neutral-700 px-3 py-1">
              <span>Correct position</span>
              <span>
                <span className="text-esc-secondary text-2xl">5</span> pts.
              </span>
            </div>
            <div className="flex justify-between items-center odd:bg-esc-neutral-700 px-3 py-1">
              <span>Off by 1</span>
              <span>
                <span className="text-esc-secondary text-2xl">3</span> pts.
              </span>
            </div>
            <div className="flex justify-between items-center odd:bg-esc-neutral-700 px-3 py-1">
              <span>Off by 2</span>
              <span>
                <span className="text-esc-secondary text-2xl">2</span> pts.
              </span>
            </div>
            <div className="flex justify-between items-center odd:bg-esc-neutral-700 px-3 py-1">
              <span>Off by 3</span>
              <span>
                <span className="text-esc-secondary text-2xl">1</span> pts.
              </span>
            </div>
          </div>

          <p className="text-center text-5xl mt-12">Good luck!</p>
        </FullscreenMessage>
      )}
      {event.stage === "guess-placement" && (
        <GuessPlacement songs={songs ?? []} />
      )}
      {event.stage === "announce-results" && (
        <FullscreenMessage
          bg={
            {
              animate: true,
              wrapperClass: "text-center",
              direction: "horizontal",
              steps: 6,
            } as GradientBackgroundProps
          }
        >
          <p className="text-center text-5xl leading-tight mb-8">
            Please wait while
            <br />
            the final results
            <br />
            are announced!
          </p>
        </FullscreenMessage>
      )}
      {event.stage === "calculate-results" && (
        <FullscreenMessage
          bg={
            {
              animate: true,
              gradient: "turquoise-yellow",
              wrapperClass: "text-center",
              direction: "horizontal",
              steps: 6,
            } as GradientBackgroundProps
          }
        >
          <p className="text-center text-5xl mb-8">Please wait!</p>
          <p className="text-center text-3xl mb-8">
            We are calculating the final scores to announce the winner of your
            party!
          </p>
          <p className="text-center text-5xl">Good luck!</p>
        </FullscreenMessage>
      )}
      {event.stage === "show-results" && <ShowResults />}
      {event.stage === "after-contest" && (
        <FullscreenMessage
          bg={{ animate: true, gradient: "turquoise-yellow", steps: 4 }}
        >
          <p className="text-center mb-8 text-4xl">🎉🎉🎉</p>
          <p className="text-center mb-8 text-3xl">
            Eurovision is over!
            <br />
            <br />
            Hope you had a great time and see you next year!
          </p>
          <p className="text-center text-4xl">🎉🎉🎉</p>
        </FullscreenMessage>
      )}
    </>
  );
}

export default LetsParty;
