import React, { useState } from "react";
import { doc, setDoc } from "firebase/firestore";

import { useApp } from "../../contexts/AppContext";
import Button from "../Button/Button";
import { db, auth } from "../../utils/firebase";

import { updateProfile } from "firebase/auth";

function SetDisplayname() {
  const {
    state: { user },
  } = useApp();
  const [nick, setNick] = useState("");
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!auth.currentUser || !user || isSaving) return;

    setIsSaving(true);

    try {
      await updateProfile(auth.currentUser, {
        displayName: nick.trim(),
      });

      await setDoc(
        doc(db, "users", user.uid),
        { nickName: nick },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col flex-1 px-4 justify-center items-center w-full">
      <h1 className="text-3xl mb-2">Almost there!</h1>
      <p className="text-center">
        You just need to create a nickname, max 15 characters!
      </p>
      <form
        autoComplete="off"
        className="mt-4 flex flex-col items-center w-11/12"
        onSubmit={handleSubmit}
      >
        <input
          id="nickname"
          className="shadow text-center appearance-none border rounded mb-1 mt-2 text-2xl w-full py-2 px-3 text-esc-neutral leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          name="nickname"
          value={nick}
          maxLength={15}
          onChange={(event) => {
            setNick(event.target.value);
            setCanSave(event.target.value.trim().length >= 2);
          }}
          placeholder="Eg. `Duncan Rybak`"
        />
        <div className="flex justify-end">
          <Button
            className="mt-4 shadow-2xl"
            type="submit"
            disabled={!canSave || isSaving}
            spinner={isSaving}
          >
            {isSaving && <>Saving nickname</>}
            {!isSaving && <>I'm ready &ndash; Let's party!</>}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SetDisplayname;
