import React from "react";

import { useApp } from "../../contexts/AppContext";
import { ReactComponent as ESCLogo } from "../../assets/logo.svg";

function Header() {
  const {
    state: { event, party },
  } = useApp();

  return (
    <header className="sticky z-10 top-0 flex justify-center items-center text-white p-4 bg-esc-secondary shadow-lg">
      {!party && (
        <h1 className="text-2xl inline-flex font-montserrat font-semibold items-center justify-center">
          Eurovision
          <span className="w-12 h-12 mx-2">
            <ESCLogo />
          </span>
          Party
        </h1>
      )}
      {party && event && (
        <>
          <div className="flex-1 flex items-center">
            {party && (
              <h1 className="text-2xl font-montserrat font-semibold leading-none">
                {party.name}
                {["pre-contest", "score-songs"].includes(event.stage) && (
                  <span className="block text-base font-montserrat">
                    <div className="">
                      Party ID:{" "}
                      <span className="tracking-widest text-esc-primary">
                        {party.shortId}
                      </span>
                    </div>
                  </span>
                )}
              </h1>
            )}
            {!party && (
              <h1 className="text-2xl font-montserrat font-semibold">
                Eurovision Party
              </h1>
            )}
          </div>
          <div className="w-12 h-12 mx-2">
            <ESCLogo />
          </div>
        </>
      )}
    </header>
  );
}

export default Header;
