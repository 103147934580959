import React from "react";

import GradientBackground from "../GradientBackground/GradientBackground";

function Modal({
  title,
  open = false,
  children,
  onClose,
}: {
  title: string;
  open?: boolean;
  children: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-auto my-6 mx-auto max-w-xs text-esc-neutral"
          style={{ width: "80vw" }}
        >
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between text-white py-2 px-4 relative">
              <GradientBackground
                steps={3}
                direction="horizontal"
                wrapperClass="flex items-center justify-between flex-1"
              >
                <h3 className="text-xl font-semibold">{title}</h3>
                <button
                  className="px-2 -mr-2 bg-transparent border-0 font-semibold outline-none focus:outline-none"
                  onClick={onClose}
                >
                  <span className="bg-transparent text-white leading-none text-3xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </GradientBackground>
            </div>
            <div className="relative p-6 flex-auto ">{children}</div>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default Modal;
