import React from "react";

export function GradientText({
  children,
  size = "5xl",
  className = "",
}: {
  size?: string;
  className?: string;
  children: React.ReactNode;
}) {
  let sizeClass = "text-base";

  switch (size) {
    case "xs":
      sizeClass = "text-xs";
      break;
    case "sm":
      sizeClass = "text-sm";
      break;
    case "md":
      sizeClass = "text-md";
      break;
    case "lg":
      sizeClass = "text-lg";
      break;
    case "xl":
      sizeClass = "text-xl";
      break;
    case "2xl":
      sizeClass = "text-2xl";
      break;
    case "3xl":
      sizeClass = "text-3xl";
      break;
    case "4xl":
      sizeClass = "text-4xl";
      break;
    case "5xl":
      sizeClass = "text-5xl";
      break;
  }
  return (
    <p
      className={`animate-text text-center ${sizeClass} text-transparent font-semibold bg-clip-text bg-gradient-to-br from-esc-primary via-esc-secondary to-esc-orange ${className}`}
    >
      {children}
    </p>
  );
}
