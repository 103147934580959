import React from "react";
import GradientBackground from "../GradientBackground/GradientBackground";

import { type GradientBackgroundProps } from "../GradientBackground/GradientBackground";

function FullscreenMessage({
  children,
  bg = {},
}: {
  children: React.ReactNode;
  bg?: GradientBackgroundProps;
}) {
  return (
    // <div className="flex flex-1 flex-col justify-center p-4 items-center text-xl bg-gradient bg-gradient-pink-yellow bg-gradient-animation relative">
    <div className="flex flex-1 flex-col justify-center p-4 items-center text-xl bg-gradient bg-blue relative">
      <GradientBackground {...bg}>{children}</GradientBackground>
    </div>
  );
}

export default FullscreenMessage;
