import { getAuth, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp, FirebaseOptions } from "firebase/app";
// import firebase from "firebase/compat/app";
// import * as firebaseui from "firebaseui";
// import "firebaseui/dist/firebaseui.css";

// import { firebaseui } from "firebaseui";
// var firebase = require("firebase");
// var firebaseui = require("firebaseui");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
// const analytics = getAnalytics(app);

const auth = getAuth(app);
auth.useDeviceLanguage();

// auth.languageCode = "nb";
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// const googleProvider = new GoogleAuthProvider();
// const signInWithAnon = async () => {
//   console.log("signInWithAnon");
//   try {
//     await signInAnonymously(auth);
//   } catch (err: any) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const logout = () => {
  signOut(auth);
};

// const ui = new firebaseui.auth.AuthUI(auth);

// const uiConfig = {
//   signInOptions: [
//     // List of OAuth providers supported.
//     // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//     // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//     // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
//     // firebase.auth.GithubAuthProvider.PROVIDER_ID,
//     // firebase.auth.EmailAuthProvider.PROVIDER_ID,
//     firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//   ],
//   // Other config options...
// };

export { app, auth, db, logout };
