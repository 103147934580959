import React from "react";

import FullscreenMessage from "../FullscreenMessage/FullscreenMessage";

function WaitingForEvent() {
  return (
    <FullscreenMessage bg={{ animate: true }}>
      <p className="text-center text-3xl mb-12">There's no active event!</p>
      <p className="text-center text-2xl">
        Please check back later or keep the page open to join when an event is
        activated.
      </p>
    </FullscreenMessage>
  );
}

export default WaitingForEvent;
