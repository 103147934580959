import React, { useState, useEffect, useRef } from "react";
// import PhoneInput, { Value as E164Number } from "react-phone-number-input";

import PhoneInput from "react-phone-input-2";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";

import Button from "../Button/Button";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ApplicationVerifier,
  ConfirmationResult,
} from "firebase/auth";
import { auth } from "../../utils/firebase";

// import { useApp } from "../../contexts/AppContext";
// import { ReactComponent as ESCLogo } from "../../assets/logo.svg";
import Modal from "../Modal/Modal";
import GradientBackground from "../GradientBackground/GradientBackground";

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
  }
}

function AuthForm({ onClose }: { onClose: () => void }) {
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [confirmRetries, setConfirmRetries] = useState<number>(0);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  // const [confirmationCode, setConfirmationCode] = useState<string>("");

  const appVerifier = useRef<null | ApplicationVerifier>(null);
  const confResult = useRef<null | ConfirmationResult>(null);

  const phoneObj = phone(phoneNumber as string);
  const canRegister = phoneObj.isValid;
  // const canVerify = confirmationCode.length === 6;

  const doRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!canRegister || !phoneNumber || !appVerifier.current || isRegistering)
      return;

    setIsRegistering(true);

    console.log(`Registering ${phoneNumber}`);

    try {
      signInWithPhoneNumber(auth, phoneNumber as string, appVerifier.current)
        .then((confirmationResult) => {
          window.recaptchaVerifier.clear();
          // console.log("confirmationResult", confirmationResult);
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          confResult.current = confirmationResult;

          // window.confirmationResult = confirmationResult;
          setConfirmRetries(0);
          setConfirm(true);
          setIsRegistering(false);

          return true;
        })
        .catch((error) => {
          // if (error.code === "auth/invalid-verification-code") {
          //   const verificationCode = window.prompt(
          //     "Invalid verification code, please try again"
          //   );
          //   if (!verificationCode) return;

          //   return window.confirmationResult.confirm(verificationCode);
          // } else {
          console.log("error", error);
          window.recaptchaVerifier.clear();
          // }
          // Error; SMS not sent
          // ...
          // window.recaptchaVerifier.render().then(function(widgetId) {
          //   grecaptcha.reset(widgetId);
          // });
          setIsRegistering(false);
        });
    } catch (error) {
      console.log("error", error);
      setIsRegistering(false);
    }
  };

  const doVerify = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!confResult.current || isVerifying) {
      return;
    }

    console.log("verify!");

    setIsVerifying(true);

    try {
      confResult.current
        .confirm(confirmationCode)
        .then(() => {
          console.log("User signed in successfully.");
          // setVerifying(false);
          // setConfirm(false);
          // onClose();
        })
        .catch((error) => {
          console.log("error", error);
          setConfirmRetries(confirmRetries + 1);
          setIsVerifying(false);
        });
    } catch (error) {
      console.log("error", error);
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "register-button",
      {
        size: "invisible",
      },
      auth
    );
    appVerifier.current = window.recaptchaVerifier;
  }, []);

  const canConfirm = confirmationCode.length >= 6;

  console.log({ isRegistering });

  if (confirm) {
    return (
      <form
        className="text-left"
        id="verify"
        autoComplete="off"
        onSubmit={doVerify}
      >
        <p className="text-base mt-4 text-center mb-4 leading-snug">
          Please enter the verification code that was sent to your mobile
          device.
        </p>
        <div className="mb-4">
          <input
            id="verification-code"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-esc-neutral leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="verification-code"
            value={confirmationCode}
            onChange={(event) => {
              setConfirmationCode(event.target.value);
            }}
            placeholder="Eg. '123456"
            maxLength={6}
          />
        </div>

        {confirmRetries > 0 && (
          <p className="text-sm text-center text-esc-accent-700">
            Invalid verification code,
            <br />
            please try again.
          </p>
        )}

        <div className="flex justify-between mt-8">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            id="register-button"
            disabled={!canConfirm || isVerifying}
            spinner={isVerifying}
            // onClick={(e) => doVerify}
          >
            {isVerifying ? "Verifying" : "Verify"}
          </Button>
        </div>
      </form>
    );
  }

  return (
    <form className="text-left" autoComplete="off" onSubmit={doRegister}>
      <PhoneInput
        country={"no"}
        placeholder="Eg. '90012345'"
        preferredCountries={["no", "se", "dk", "gb", "es", "fi", "is"]}
        value={phoneNumber}
        onChange={(phone) => setPhoneNumber(`+${phone}`)}
        inputClass="!w-full"
        countryCodeEditable={false}
        inputProps={{
          required: true,
        }}
      />
      <p className="text-sm mt-4 text-center">
        Your phone number is only used to authenticate and identify you as a
        unique user. It will be stored on your user object in our database, but
        will be deleted within 30 days after the Eurovision final.
      </p>
      <div className="flex justify-between mt-8">
        <Button type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!canRegister || isRegistering}
          id="register-button"
          spinner={isRegistering}
        >
          {isRegistering ? "Registering" : "OK"}
        </Button>
      </div>
    </form>
  );
}

function Intro() {
  const [openAuth, setOpenAuth] = useState(false);

  return (
    <div className="flex-1 flex items-center flex-col px-4 justify-center text-center text-white">
      <GradientBackground animate>
        <h1 className="text-5xl font-bold leading-tight font-montserrat mb-8">
          Do you think you know Eurovision?
        </h1>
        <p className="text-2xl mt-2 mb-8 px-4">
          Challenge your friends and family to guess this years final results!
        </p>
        <Button
          size="2xl"
          className="shadow-2xl"
          onClick={() => {
            setOpenAuth(true);
          }}
        >
          Register / Login
        </Button>
        {openAuth && (
          <Modal title="Register / Login" onClose={() => setOpenAuth(false)}>
            <AuthForm onClose={() => setOpenAuth(false)} />
          </Modal>
        )}
        {/* <Button variant="secondary" disabled>
        Disabled
      </Button> */}
        <div id="firebaseui-auth-container"></div>
      </GradientBackground>
    </div>
  );
}

export default Intro;
