import React from "react";

import { useApp } from "../../contexts/AppContext";
import GradientDivider from "../GradientDivider/GradientDivider";
import GradientBackground from "../GradientBackground/GradientBackground";
import { GradientText } from "../GradientText/GradientText";

function PreContest() {
  const {
    state: { party, partyUsers, user },
  } = useApp();

  if (!party || !user) return null;

  const maxVisibleParticipants = 20;

  return (
    <div className="flex flex-1 flex-col justify-center items-center">
      <div className="flex-1 flex flex-col justify-center items-center">
        <GradientText size="3xl">
          Waiting for the
          <br />
          Eurovision Song Contest
          <br />
          to start!
        </GradientText>
      </div>

      {partyUsers && (
        <div className="flex-1 text-xl w-full flex relative">
          <GradientBackground
            direction="horizontal"
            animate
            steps={6}
            wrapperClass="flex-1 flex flex-col justify-center items-center"
          >
            <p className="text-whitetext-xl mb-2">
              Participants ({Object.keys(partyUsers).length})
            </p>
            <ul className="flex flex-wrap items-center justify-center gap-2">
              {Object.values(partyUsers)
                .slice(0, maxVisibleParticipants)
                .map((_data) => {
                  if (!_data.name) return null;

                  const cssClass = `whitespace-nowrap py-1 px-3 text-sm rounded-full bg-gradient-to-br tracking-wide ${
                    user.uid === _data.userId
                      ? "text-white border-2 border-esc-turquoise"
                      : "text-white border border-white"
                  }`;

                  return (
                    <li className={cssClass} key={_data._docId}>
                      {_data.name}
                    </li>
                  );
                })}
              {Object.keys(partyUsers).length > maxVisibleParticipants && (
                <li>
                  + {Object.keys(partyUsers).length - maxVisibleParticipants}{" "}
                  more
                </li>
              )}
            </ul>
          </GradientBackground>
        </div>
      )}

      <div className="flex-1 flex flex-col justify-center items-center">
        <p className="text-center text-xl">
          {!partyUsers ||
            (Object.values(partyUsers).length < 3 && (
              <span className="leading-normal">
                It's more fun to play with others!
                <br />
                Invite someone to join this party!
              </span>
            ))}
          {partyUsers && Object.values(partyUsers).length >= 3 && (
            <span>Invite more people to join this party!</span>
          )}
          <br />
          <span className="text-esc-primary mt-4 inline-block text-6xl tracking-widest font-montserrat">
            {party.shortId}
          </span>
        </p>
      </div>
    </div>
  );
}

export default PreContest;
