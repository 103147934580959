import React from "react";
import "./App.css";
import { AppProvider, useApp } from "../../contexts/AppContext";
import Intro from "../Intro/Intro";
import LoggedIn from "../LoggedIn/LoggedIn";

function AppInner() {
  const {
    state: { isReady, user },
  } = useApp();

  return (
    <div className="flex min-h-screen flex-col max-w-lg mx-auto">
      {/* {!isReady && <p>Creating session...</p>} */}
      {isReady && (
        <>
          {!user && <Intro />}
          {user && <LoggedIn />}
        </>
      )}
    </div>
  );
}

function App() {
  return (
    <AppProvider>
      <AppInner />
    </AppProvider>
  );
}

export default App;
