import React from "react";
import Header from "../Header/Header";
import SetDisplayname from "../SetDisplayname/SetDisplayname";
import Event from "../Event/Event";
import WaitingForEvent from "../WaitingForEvent/WaitingForEvent";

import { RecaptchaVerifier, ConfirmationResult } from "firebase/auth";

import { useApp } from "../../contexts/AppContext";

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
  }
}

function LoggedIn() {
  const {
    state: { globalUser, event },
  } = useApp();

  const hasNickname = globalUser?.nickName;

  return (
    <>
      <Header />
      {!hasNickname && <SetDisplayname />}
      {hasNickname && !event && <WaitingForEvent />}
      {hasNickname && event && <Event />}
    </>
  );
}

export default LoggedIn;
