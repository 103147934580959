import React from "react";

import { useApp } from "../../contexts/AppContext";

import CreateOrJoinParty from "../CreateOrJoinParty/CreateOrJoinParty";
import LetsParty from "../LetsParty/LetsParty";

function Event() {
  const {
    state: { party, partyUser },
  } = useApp();

  return (
    <>
      {!partyUser?.partyId && <CreateOrJoinParty />}
      {/* {partyUser?.partyId && !partyUser?.name && <RequestNickname />} */}
      {partyUser?.partyId && partyUser?.active && party && <LetsParty />}
    </>
  );
}

export default Event;
